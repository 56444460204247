import Vue from "vue";
import Options from "vue-class-component";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
import Multiselect from "vue-multiselect";
import { IBranch } from "@/models/IBranch";
import { Watch } from "vue-property-decorator";
const storedUser = localStorage.getItem("user");
@Options({
  name: "NavbarComponent",
  components: {
    Multiselect,
  },
})
export default class Navbar extends Vue {
  private isShow = false;
  branches: any = [];
  bId:any = {};

  public user = storedUser ? JSON.parse(storedUser) : null;

  @Auth.State("user")
  private currentUser!: any;

  @Auth.State("status")
  private collapse!: any;

  @Auth.State("status")
  private branchId!: number;
  isload: boolean = false;

  @Auth.Action
  private signOut!: () => void;

  @Auth.Mutation
  private setCollapseable!: (newValue: boolean) => void;

  @Auth.Mutation
  private setBranchId!: (newValue: number) => void;

  @Auth.Getter
  private getCurrentUser!: any


  created(){

      this.branches = this.user.branches

    if (this.branches !== undefined)
    {
        var obj = this.branches.find(o => o.isDefault === true)

      if (obj !== undefined)
      {
        this.bId = obj
        this.setBranchId(this.bId.branchId)

      }
      else{
        this.bId = this.branches[0]
        this.setBranchId(this.bId.branchId)
      }

      this.isload = true

      console.log("branches " + JSON.stringify( this.branches))
    }


  }

  logOut() {
    this.signOut();
    this.$router.push("/");
  }

  onChange() {
      this.setBranchId(this.bId.branchId)
  }

  toggleSidebar() {

    this.isShow = !this.isShow;
    this.setCollapseable(this.isShow);
    //this.$store.commit("isCollapseChange", this.isShow);
  }
}
