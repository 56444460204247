import 'bootstrap/dist/js/bootstrap.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Vue from 'vue'
import  Options from 'vue-class-component';
import SidebarComponent from '@/components/Sidebar/index.vue';
import NavbarComponent from '@/components/Navbar/index.vue';
import { namespace } from "vuex-class";
import "vuesax/dist/vuesax.css";

const Auth = namespace("Auth");

@Options({
    components: {
        SidebarComponent,
        NavbarComponent,
    },
})
export default class Admin extends Vue {
    @Auth.State("user")
    private currentUser!: any;

    mounted() {
      if (!this.currentUser) {
        this.$router.push("/");
      }
    }

}
